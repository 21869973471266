.quotation {
    position: relative;

    &--loading {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #008c95;
        color: #fff;
        font-weight: bold;
        z-index: 9999;
        width: 100vw;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item-content-specs {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &__item-content-specs--small {
        font-size: 0.8rem;
    }

    &__item-content-specs .specs-value {
        width: 100%;
    }

    &__item-content-specs th,
    &__item-content-specs td {
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    &-addresses {
        padding-top: 2rem;

        &__address-title{

        }
    }
}
