.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    margin-left: rem-calc(9999);
    opacity: 0;
    transition: opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear .4s;
    $modal: &;

    &__close {
        position: absolute;
        left: rem-calc(-15);
        top: rem-calc(-15);
        z-index: 1;
        border-radius: 50%;
        background-color: map-deep-get($colors, "green", "default");
        width: rem-calc(30);
        height: rem-calc(30);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            width: rem-calc(18);
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
    }

    &__content {
        background-color: map-deep-get($colors, "white", "default");
        border-radius: $border-radius-default;
        padding: 24px;
        position: relative;
        z-index: 2;
        transform: translateY(30px);
        transition: transform .4s cubic-bezier(.2, 0, 0, 1);

        @include breakpoint(xlarge) {
            min-width: rem-calc(500);
        }
    }

    &__title {
        margin-bottom: rem-calc(24);
    }

    &__buttons {
        display: flex;
        margin-top: rem-calc(24);

        .checkbox-wrapper {
            margin: 8px 24px;
        }
    }

    &__message {
        margin-top: rem-calc(24);
    }

    &__button {
        margin-right: rem-calc(16);
    }

    &__link {
        color: map-deep-get($colors, "green", "default");
        display: inline-block;
        margin-right: rem-calc(16);
        padding: 8px 24px;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__share {
        margin-top: rem-calc(16);
    }

    &__share-checkbox-wrapper {
        margin-top: rem-calc(16);
    }

    &--visible {
        opacity: 1;
        margin-left: 0;
        transform: translateY(0);
        transition: transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear;

        #{$modal}__content {
            transform: translateY(0);
        }
    }

    &--fullscreen:not(&--small) &__content {
        width: 95vw;
    }

    &__results .accountmanager-overview__results-list {
        overflow-y: auto;
        max-height: calc(90vh - 300px);
        margin-bottom: 20px;
    }
}
